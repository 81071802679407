'use client';

import { FarcasterAuthProvider } from '../FarcasterAuthProvider';
import SolanaWalletProvider from '../SolanaWalletProvider/SolanaWalletProvider';
import { Web3ModalProvider } from '../Web3ModalProvider';

export default function BlockchainProvidersLayout({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <FarcasterAuthProvider>
      <Web3ModalProvider>
        <SolanaWalletProvider>{children}</SolanaWalletProvider>
      </Web3ModalProvider>
    </FarcasterAuthProvider>
  );
}
